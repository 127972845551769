.about-bx3{
	.dz-media{
		position: relative;
		text-align: center;
		overflow: visible;
		margin-left: -50px;
		padding-right: 50px;
	
		img{
			width: 100%;
			height: 100%;
		}
		&:before{ 
			content: '';
			position: absolute;
			border-radius: 25px;
			transform: skew(-15deg);
			z-index: -1;
			background-color: var(--primary);
			height: 300px;
			width: 290px;
			left: -70px;
			top: 150px;
		}
	}
	.section-head {
		margin-bottom: 30px;
	}
	@include respond ('tab-land'){
		.dz-media{
			margin-left: 50px;
			padding-right: 30px;
	
			&:before{
				height: 290px;
				width: 250px;
				left: -20px;
				bottom: 170px;
			}
		}
	}
	@include custommq ($max:1199px){
		.dz-media{
			margin-left: auto;
			margin-right: auto;
			padding-right: 0;
			
			&:before{
				height: 260px;
				width: 230px;
				bottom: 100px;
				left: -10px;
			}
		}
	}
	@include respond ('tab-port'){
		flex-direction: column-reverse;
		
		.dz-media{
			img{
				width: 390px;
			}
			&:before{
				height: 290px;
				width: 260px;
				left: 70px;
				bottom: 130px;
			}
		}
	}
	@include respond ('phone'){
		.dz-media{
			img{
				width: 250px;
			}
			&:before{
				height: 160px;
				width: 160px;
				left: 20px;
				top: 70px;
				border-radius: 10px;
			}
		}
	}
	
}
  