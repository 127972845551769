.dz-card{
	&.style-4{
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		background-color: #fff;
		border-radius: var(--border-radius-base);
		height: 100%;
		margin-top: 20px;
		
		&:after{
			background-color: var(--dark);
			border-radius: var(--border-radius-base);
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			left: 0;
			top: 0;
			@include transitionSlow;
			border: 2px solid var(--dark);
		}
		.dz-meta{
			margin-bottom: 20px;
			
			ul li{
				color: #fff;
				position: relative;
				font-size: 15px;
				margin-right: 30px;
				
				&:after{
					content: "";
					height: 6px;
					width: 6px;
					border-radius: 6px;
					background-color: #fff;
					right: -20px;
					top: 50%;
					position: absolute;
					transform: translateY(-50%);
				}
				&:nth-last-child(2),
				&:last-child{
					margin-right: 0;
					
					&:after{
						content: none;
					}
				}
			}
			.post-author{
				img{
					margin-right: 10px;
					border-radius: 50%;
					border: 2px solid #fff;
					width: 35px;
					height: 35px;
				}
			}
			.post-date{
				background-color: var(--primary);
				padding: 10px;
				display: inline-block;
				text-align: center;
				border-radius: var(--border-radius-base);
				position: absolute;
				left: 25px;
				top: -15px;
				
				.day{
					font-size: 45px;
					color: #fff;
					font-weight: 700;
					display: block;
					line-height: 45px;
				}
				.month{
					color: #fff;
					margin-bottom: 0;
					font-size: 14px;
				}
			}
			@include respond('tab-port'){
				margin-bottom: 10px;
				.post-author{
					img{
						display: none;
					}
				}
			}
			@include respond('phone'){
				.post-date{
					.day{
						font-size: 35px;
						line-height: 35px;
					}
				}
			}
		}
		.dz-info {
			border-radius: var(--border-radius-base);
			padding: 105px 25px 25px;
			z-index: 1;
			@include transitionSlow;
			p{
				color: #fff;
				margin-bottom: 25px;
				opacity: 0.7;
			}
			.dz-title{
				margin-bottom: 10px;
				a{
					color: #fff;
				}
			}
			@include respond('phone'){
				padding: 85px 25px 25px;
			}
		}
		&:hover{
			&:after{
				opacity: 0.8;
				border-color: var(--primary);
			}
		}
	}
}