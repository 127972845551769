// dz-accordion
.dz-accordion{
	.accordion-item{
		border-radius: var(--border-radius-base);
		overflow: hidden;
		margin-bottom: 20px;
		border: 1px solid rgba(0, 0, 0, 0.125) !important;
		
		@include respond('phone'){
			margin-bottom: 15px;
		}
	}
	.accordion-body{
		padding: 25px 30px;
		border-radius: 0;
	}
	.accordion-collapse{
		border: 0;
	}
	.accordion-header{
		.accordion-button{
			font-size: 18px;
			border: 0;
			border-radius: var(--border-radius-base);
			padding: 22px 65px 22px 30px;
			transition: all 0.5s;
			color: $white;
			background-color: var(--primary);
			font-weight: 600;
			
			&:after{
				content: none;
			}
			
			.toggle-close{
				background-color: $white;
				font-family: themify;
				height: 55px;
				width: 55px;
				display: block;
				position: absolute;
				right: 5px;
				top: 5px;
				border-radius: var(--border-radius-base);
				line-height: 57px;
				text-align: center;
				font-size: 24px;
				@include transitionMedium;
				z-index:1;
				overflow:hidden;
				
				&:after{
					content: "";
					position:absolute;
					z-index:-1;
					background-color:var(--primary);
					@include transitionMedium;
					width:100%;
					height:100%;
					left:0;
					top:0;
					opacity:0;
				}
				
				&:before{
					content: "\f078";
					color: var(--primary);
					font-size: 18px;
					font-weight: 800;
					font-family: "Font Awesome 5 Free";
					z-index: 99;
					opacity: 1;
					transform: rotate(0);
					transition: all 0.5s;
					line-height: 55px;
					display: block;
				}
			}
			
			&.collapsed{
				background: $white;
				color: inherit;
				&:after{
					opacity:0;
				}
				.toggle-close{
					content: "";
					&:after{
						opacity:1;
					}
					&:before{
						opacity: 1;
						transform: rotate(-90deg);
						color:$white;
					}
				}
				
			}
		}
		@include respond ('phone-land'){
			.accordion-button{
				font-size: 16px;
			}
		}
		@include respond('phone'){
			.accordion-button{
				font-size: 14px;
				padding: 12px 55px 12px 15px;
			}
		}
	}
	&.accordion-sm{
		.accordion-body {
			padding: 25px;
		}
		.accordion-header{
			.accordion-button{
				padding: 15px 55px 15px 20px;
				.toggle-close{
					height: 40px;
					width: 40px;
					&:before{
						font-size: 14px;
						line-height: 40px;
					}
				}
			}			
		}
		@include respond('phone'){
			.accordion-header{
				.accordion-button{
					padding: 12px 55px 12px 15px;
				}
			}
			.accordion-body{
				padding: 20px;
			}
		}
	}
}