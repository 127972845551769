.about-bx5{
	.section-head{
		margin-bottom: 30px;
	}
	.dz-about-media{
		position: relative;
		overflow: unset;
		margin-right: 20px;
		padding-top: 100px;
		
		.img-wrapper{
			position: absolute;
			top: 0;
			left: -70px;
			width: 330px;
			
			.video-btn{
				position: absolute;
				background-color: var(--primary);
				bottom: 20px;
				right: 20px;
				width: 60px;
				height: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1;
				font-size: 20px;
				i{
					color: #fff;
				}
			}
			.img1{
				margin-right: 0;
				z-index: 1;
				padding: 0 20px 20px 0;
				background-color: #fff;
				border-radius: var(--border-radius-base);
			}
		}
		.img2 {
			border-radius: var(--border-radius-base);
			width: 100%;
			height: 100%;
			z-index: -1;
			overflow: hidden;
			position: relative;
			clip-path: polygon(50% 0%, 100% 0, 100% 80%, 85% 100%, 51% 100%, 0 100%, 0 0);
			img{
				border-radius: var(--border-radius-base);				
			}
		}		
		@include respond('tab-land'){
			padding-left: 80px;
			.img-wrapper .img1 {
				padding: 0 10px 10px 0;
			}
			.img-wrapper {
				left: 0;
				width: 250px;
			}
		}
		@include respond('phone'){
			margin-right: 0;
			.img-wrapper{
				width: 180px;
				.img1{
					padding: 0 5px 5px 0;
				}
				.video-bx{
					bottom: 15px;
					right: 15px;
					width: 40px;
					height: 40px;
				}
			}
		}
	}
	
	.dz-about-info{
		border-left: 5px solid var(--primary);
		padding: 10px 0 10px 30px;
		margin-bottom: 40px;
		
		@media only screen and (max-width: 1191px){			
			padding: 5px 0 5px 25px;
		}
	}
}