.blog-swiper{
	margin: -15px;
    width: auto;
	.swiper-slide{
		& > div{
			margin:15px;	
			height:100%;
		}	
	}	
}
.dz-card{
	position:relative;
	
	.dz-info{
		padding:30px;
		position:relative;
	}
	.dz-title{
		margin-bottom:15px;
		word-break: break-word;
		@include respond('phone'){
			margin-bottom:15px;
		}
	}
	&.blog-half{
		display:flex;
		margin-bottom: 30px;
		.dz-info{
			padding:30px;
			position:relative;
			flex: 1;
		}
		.dz-media{
			margin-bottom: 0;
			max-width: 300px;
			min-width: 300px;
			img{
				height:100%;
				object-fit:cover;
				min-height: 235px;
				@include respond('phone'){
					min-height: auto;
				}
			}
		}
		&.post-video .post-video-icon{
			width: 60px;
			height: 60px;
			font-size: 20px;
		}
		.swiper-container{
			height: 100%;
		}
		@include respond('phone-land'){
			display:block;
			
			.dz-media{
				max-width: 100%;
				min-width: 100%;
			}
		}
	}
}
.dz-media{
	&.height-sm {
		height:220px;
		img {
			height:100%;
			object-fit:cover;
		}
	}
	&.height-md {
		height:265px;
		img {
			height:100%;
			object-fit:cover;
		}
		@include custommq($max: 1199px) {
			height:220px;
		}
		@include respond ('phone-land'){
			height:200px;
		}
	}
	&.height-lg {
		height:350px;
		@include custommq($max: 1199px) {
			height:300px;
		}
		@include respond ('phone-land'){
			height:220px;
			img {
				height:100%;
				object-fit:cover;
			}
		}
	}
}
.dz-meta{
	margin-bottom:10px;
	ul{
		margin:0;
		padding:0;
		li{
			display:inline-block;
			color: #777;
			margin-right:20px;
			
			&:last-child{
				margin-right:0;
			}
			i{
				margin-right: 5px;
				color:var(--primary);	
			}
			a{
				color: #777;	
			}	
		}	
	}	
}
.dz-badge {
    background-color: var(--primary);
    color: #fff;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 15px;
	&:hover {
		color:white;
	}
}
.post-video .post-video-icon {
    width: 100px;
    height: 100px;
	line-height: 100px;
	border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
	color: var(--primary);
	font-size: 30px;
	margin: 0 auto;
   background-color: $white;
    transform: translate(-50%, -50%);
    // -moz-transform: translate(-50%, -50%);
    // -webkit-transform: translate(-50%, -50%);
    // -ms-transform: translate(-50%, -50%);
    // -o-transform: translate(-50%, -50%);
    // -webkit-transition: all 1s;
    // -ms-transition: all 1s;
    transition: all 1s;
	box-shadow: 0 0 0 8px rgba(#fff,0.5);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
	
	@include respond('phone'){
		height: 50px;
		width: 50px;
		line-height: 50px;
		font-size: 14px;
	}
	&:before{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border: 1px solid #fff;
		// -webkit-border-radius: 50%;
		// -khtml-border-radius: 50%;
		// -moz-border-radius: 50%;
		// -ms-border-radius: 50%;
		// -o-border-radius: 50%;
		border-radius: 100%;
		animation: animationSignal1;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		// -webkit-animation: animationSignal1;
		// -webkit-animation-iteration-count: infinite;
		// -webkit-animation-duration: 3s;
		z-index: -1;
		transform:  scale(1);		
		transition:all 0.5s;
	}
	&:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: block;
		border: 1px solid #fff;
		-webkit-border-radius: 100%;
		-khtml-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		border-radius: 100%;
		animation: animationSignal2;
		animation-iteration-count: infinite;
		animation-duration: 3s;
		-webkit-animation: animationSignal2;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
		z-index: -1;
		transform:  scale(1);
		// -moz-transform:  scale(1);
		// -webkit-transform:  scale(1);
		// -ms-transform:  scale(1);
		// -o-transform:  scale(1);
		// -moz-transition:all 0.5s;
		// -o-transition:all 0.5s;
		// -webkit-transition:all 0.5s;
		// -ms-transition:all 0.5s;
		transition:all 0.5s;
	}
}
