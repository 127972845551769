#loading-area{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
	background-position:center;
	background-repeat:no-repeat;
	background-size:80px;
	background: #fff;
	display:flex;
	align-items:center;
	justify-content: center;
	
	&.loading-page-1{
		background: #faf8f2;
		.item-wrapper{
			height: 60px;
			width: 68px;
			position: relative;
			margin-left: auto;
			margin-right: auto;
			
			.item1{
				position: absolute;
				left: 0;
				animation: leftHand 1.2s linear infinite;
			}
			.item2{
				position: absolute;
				right: 0;
				animation: rightHand 1.2s linear infinite;
			}
			.item3{
				position: absolute;
				left: 17px;
				// -webkit-animation: HeartUpToDown 1.5s infinite;
				// -moz-animation: HeartUpToDown 1.5s infinite;
				animation: HeartUpToDown 1.5s infinite;
			}
		}
		.load-text{
			margin-top: 5px;
			
			.text-load{
				font-weight: 800;
				display: inline-block;
				color: rgba(0, 0, 0, 0.1);
				position: relative;
				font-size: 70px;
				line-height: 1.4;
				font-size: 24px; 
				text-align: center;
				user-select: none;
				text-transform: uppercase;
				font-family: var(--font-family-title);
				letter-spacing: -1px;
					
				&:before {
					animation: lettersLoading 4s infinite;
					color: var(--title);
					content: attr(data-text);
					left: 0;
					opacity: 0;
					top:0;
					line-height: 1.4;
					position: absolute;
					text-transform: uppercase;
				}
				&:nth-child(2):before {animation-delay: 0.2s;}
				&:nth-child(3):before {animation-delay: 0.4s;}
				&:nth-child(4):before {animation-delay: 0.6s;}
				&:nth-child(5):before {animation-delay: 0.8s;}
				&:nth-child(6):before {animation-delay: 1s;}
				&:nth-child(7):before {animation-delay: 1.2s;}
				&:nth-child(8):before {animation-delay: 1.4s;}
			}
		}
	}
	&.loading-page-2{
		.loading-inner{
			img{
				width: 250px;
			}
		}
	}
	&.loading-page-3{
		.loading-inner{
			width: 100px;
			height: 100px;
			margin: auto;
			animation: animate 1s infinite;
			position: absolute;
			
			div{
				width: 35%;
				height: 35%;
				border-radius: 50%;
				position: absolute;
				animation: dzSpeed 2s infinite ease-in-out;
			}
			.item1{
				background: var(--primary);
				animation-delay: 1.5s;
			}
			.item2{
				background: rgb(247,185,93);
				animation-delay: 1s;
			}
			.item3{
				background: #fa85f8;
				animation-delay: 0.5s;
			}
			.item4{
				background: #fe8282;
			}
		}
	}
}

@keyframes dzSpeed{
	0%{
		border-radius: 50%;
		transform: translate(0%);
	}
	25%{
		border-radius: 0;
		transform: translate(150%) scale(0.5);
	}
	50%{
		border-radius: 50%;
		transform: translate(150%, 150%);
	}
	75%{
		border-radius: 0;
		transform: translate(0, 150%) scale(0.5);
	}
}

@keyframes dotScale{
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes HeartUpToDown{
	0%{
		transform: translateY(-35px);
		opacity: 0;
	}
	25%{
		opacity: 0.4;
	}
	50%{
		opacity: 0.6;
	}
	75%{
		opacity: 0.8;
	}
	100%{
		opacity: 1;
		transform: translateY(-5px);
	}
}
@keyframes leftHand{
    0%{
		transform: rotate(-20deg) translateX(-20px);
    }
	100%{
		transform: rotate(0) translateX(0);
	}
}
@keyframes rightHand{
    0%{
		transform: rotate(20deg) translateX(20px);
	}
	100%{
		transform: rotate(0) translateX(0);
    }
}

@keyframes lettersLoading{
	0%,
	75%,
	100% {
		opacity: 0;
		transform: rotateY(-90deg);
	}
	25%,
	50% {
		opacity: 1;
		transform: rotateY(0deg);
	}
}