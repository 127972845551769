:root{
	--primary: rgb(12,96,129);
	--secondary: rgb(247,185,93);
	--secondary-hover: #f64f40;
	--primary-hover: #186f61;
	--primary-dark: #ea4619;
	--rgba-primary-1: rgba(27,130,113,0.1);
	--rgba-primary-2: rgba(27,130,113,0.2);
	--rgba-primary-3: rgba(27,130,113,0.3);
	--rgba-primary-4: rgba(27,130,113,0.4);
	--rgba-primary-5: rgba(27,130,113,0.5);
	--rgba-primary-6: rgba(27,130,113,0.6);
	--rgba-primary-7: rgba(27,130,113,0.7);
	--rgba-primary-8: rgba(27,130,113,0.8);
	--rgba-primary-9: rgba(27,130,113,0.9);
	--title: #071c35;
}