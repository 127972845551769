// form-label
.form-label{
	font-size    : 14px;
	font-weight  : 500;
	color        : $body-color;
	margin-bottom: 10px;
	background   : transparent;
	border-color : $border-color;
}

// form-control
.form-control {
    height    	 : 55px;
    border       : 1px solid #d2d2d2;
    padding      : 10px 20px;
    font-size    : 14px;
    font-weight  : 400;
    color        : $body-color;
    transition   : all 0.3s ease-in-out;
	background   : #FFF;
	border-radius: var(--border-radius-base)!important;
    .select {
        padding: 12px 0;
    }

    span {
        margin-top: 0;
    }
	&.transparent{
		background: rgba(255,255,255,0.1);
		border: 1px solid rgba(255,255,255,0.2);
		color: #fff;
		
		&::placeholder{
			color: #fff;
		}
	}
    &::-webkit-input-placeholder {
        color: $body-color;
    }

    &:-ms-input-placeholder {
        color: $body-color;
    }

    &::placeholder {
        color: $body-color;
    }
	&:focus,
    &:active,
    &.active {
        border-color: var(--primary);
    }
}
textarea.form-control{
    min-height: unset;
	height: auto;
}
// textarea
textarea{
	height: 120px;
	resize: none;
}

input:-internal-autofill-selected {
    background      : lighten($body-bg, 2%) !important;
    background-image: none !important;
    color           : -internal-light-dark-color(black, white) !important;
}

.input-group-text {
    padding         : 5px 15px;
    background      : $body-bg;
    margin-bottom   : 0px !important;
    color           : $body-color;
    border-color    : transparent;
}

// toggle
.toggle {
    cursor          : pointer;
    display         : block;
}
.toggle-switch {
    display       : inline-block;
    background    : darken($body-bg, 3%);
    border-radius : 16px;
    width         : 45px;
    height        : 20px;
    position      : relative;
    vertical-align: middle;
    transition    : background 0.25s;

    &:before,
    &:after {
        content: "";
    }

    &:before {
        display      : block;
        background   : linear-gradient(to bottom, $white 0%, #eee 100%);
        border-radius: 50%;
        width        : 17px;
        height       : 17px;
        position     : absolute;
        top          : 1px;
        left         : 3px;
        transition   : left 0.25s;
    }

    .toggle:hover &:before {
        background   : linear-gradient(to bottom, $white 0%, $white 100%);
    }

    .toggle-checkbox:checked+& {
        background: var(--primary);

        &:before {
            left: 26px;
        }
    }
}
.toggle-checkbox {
    position  : absolute;
    visibility: hidden;
}
.toggle-label {
    margin-left: 15px;
    position   : relative;
    top        : 2px;
    font-size  : 16px;
    font-weight: 400;
}

// Upload btn
.file-upload-wrapper {
    position     : relative;
    width        : 100%;
    height       : calc(1.5em + 1rem + 2px);
    border       : 1px solid $border-color;
    border-radius: 5px;
    color        : $text-muted;

    &:after {
        content       : attr(data-text);
        position      : absolute;
        top           : 0;
        left          : 0;
        background    : $body-bg;
        padding       : 4px 15px;
        display       : block;
        width         : calc(100% - 40px);
        pointer-events: none;
        z-index       : 20;
        height        : calc(1.5em + 1rem + 2px);
        line-height   : 50px - 20px;
        border-radius : 5px 10px 10px 5px;
        font-weight   : 400;
        overflow      : hidden;
    }

    &:before {
        content : 'Upload';
        position: absolute;
        top     : 0;
        right   : 0;
        display : inline-block;
        height  : calc(1.5em + 1rem + 2px);
        background    : var(--primary);
        color         : $white;
        font-weight   : 400;
        z-index       : 25;
        font-size     : 14px;
        line-height   : 40px;
        padding       : 0 15px;
        text-transform: capitalize;
        pointer-events: none;
        border-radius : 0 5px 5px 0;
    }

    &:hover {
        &:before {
            background: darken($primary, 10%);
        }
    }

    input {
        opacity : 0;
        position: absolute;
        top     : 0;
        right   : 0;
        bottom  : 0;
        left    : 0;
        z-index : 99;
        height  : calc(1.5em + 1rem + 2px);
        margin  : 0;
        padding : 0;
        display : block;
        cursor  : pointer;
        width   : 100%;
    }
}

// Date picker
#ui-datepicker-div {
    display      : none;
    background   : darken($body-bg, 2%);
    box-shadow   : 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top   : 0.25rem;
    border-radius: 0.5rem;
    padding      : 0.5rem;
}

table {
    border-collapse: collapse;
    border-spacing : 0;
}

.ui-datepicker-calendar {
    thead th {
        padding    : 0.25rem 0;
        text-align : center;
        font-size  : 0.75rem;
        font-weight: 400;
        color      : $body-color;
    }

    tbody td {
        width     : 2.5rem;
        text-align: center;
        padding   : 0;

        a {
            display        : block;
            border-radius  : 0.25rem;
            line-height    : 2rem;
            transition     : 0.3s all;
            color          : $body-color;
            font-size      : 0.875rem;
            text-decoration: none;

            &:hover {
                background-color: var(--primary);
                color           : white;
            }

            &.ui-state-active {
                background-color: var(--primary);
                color           : white;
            }
        }
    }
}

.ui-datepicker-header a {
    &.ui-corner-all {
        cursor       : pointer;
        position     : absolute;
        top          : 0;
        width        : 2rem;
        height       : 2rem;
        margin       : 0.5rem;
        border-radius: 0.25rem;
        transition   : 0.3s all;

        &:hover {
            background-color: lighten($primary, 30%);
            color           : $white;
        }
    }

    &.ui-datepicker-prev {
        left               : 0;
        background         : url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
        background-repeat  : no-repeat;
        background-size    : 0.5rem;
        background-position: 50%;
        transform          : rotate(180deg);
    }

    &.ui-datepicker-next {
        right              : 0;
        background         : url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
        background-repeat  : no-repeat;
        background-size    : 10px;
        background-position: 50%;
    }

    >span {
        display: none;
    }
}

.ui-datepicker-title {
    text-align    : center;
    line-height   : 2rem;
    margin-bottom : 0.25rem;
    font-size     : 0.875rem;
    font-weight  : 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color      : $body-color;
    font-weight: 400;
    font-size  : 0.75rem;
}

// Validation Error
label.error {
    color        : $danger;
    position     : absolute;
    bottom       : 0;
    margin-bottom: -22px;
    font-size    : 12px;
    font-weight  : 400;
}

// Nice Select
.nice-select {
    display                  : flex;
    align-items              : center;
    border-top-left-radius   : 3px !important;
    border-bottom-left-radius: 3px !important;

    ul {
        width: 100% !important;
    }
}

.nice-select .list {
    background: $dark;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: $dark;
    color           : var(--primary);
}



// Select Opting
.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}
.form-select{
	padding: 0;
	border: 1px solid transparent;
	background-image: none;
	
	& > select{
		display: none!important;
	}
	.dropdown-toggle {
		border: 0 !important;
		padding: 0;
		font-weight: normal;
		text-transform: unset;
		outline:none;
		&:focus {
			outline: 0 !important;
			outline-offset: 0;
		}
		&:after{
			content:none;
		}
	}
	&.show {
		box-shadow: none !important;
		outline: 0px !important;
		.dropdown-toggle {
			box-shadow: none !important;
			outline: 0px !important;
		}
		&.btn-group .dropdown-menu.inner{
			display:block;
		}	
	}
	.dropdown-menu {
		border: 0;
		border-radius: 0;
		box-shadow: none;
		margin-top: -1px;
		padding: 10px 0;
		box-shadow:0 15px 30px 0 rgba(0,0,0,0.2);
		font-size:14px;
		ul li {
			padding: 0;
			a {
				display: block;
				padding: 8px 20px;
				clear: both;
				font-weight: 500;
				color: #333;
				white-space: nowrap;
				font-size: 15px;
				
				img {
					border-radius: 100%;
					-webkit-border-radius: 100%;
					margin-right: 5px;
				}
				&:hover {
					background-color:#f2f2f4;
				}
			}
			&.selected{
				a{
					background-color: var(--primary);
					color: $white;
				}
			}
		}
	}
	&:after{
		content: "\f138";
		font-family: flaticon;
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-weight: 700;
		line-height: 1;
		font-size: 15px;
	}
	&:focus,
    &:active,
    &.active {
        border-color: var(--primary);
    }
}

.form-select{
	padding: 0;
	border: 1px solid transparent;
	background-image: none;
	border: 0!important;
	
	& > select{
		display: none!important;
	}
	.dropdown-toggle{
		padding: 0;
		font-weight: normal;
		text-transform: unset;
		outline:none;
		border: 1px solid $border;
		&:focus {
			outline: 0 !important;
			outline-offset: 0;
		}
		&:focus,
		&:active,
		&:hover{
			border-color: var(--primary);
		}
		&:after{
			content:none;
		}
	}
	&.show {
		box-shadow: none !important;
		outline: 0px !important;
		.dropdown-toggle {
			box-shadow: none !important;
			outline: 0px !important;
		}
		&.btn-group .dropdown-menu.inner{
			display:block;
		}	
	}
	.dropdown-menu {
		border: 0;
		border-radius: 0;
		box-shadow: none;
		margin-top: -1px;
		padding: 10px 0;
		box-shadow:0 15px 30px 0 rgba(0,0,0,0.2);
		font-size:14px;
		border-radius: var(--border-radius-base);
		ul li {
			padding: 0;
			a {
				display: block;
				padding: 8px 20px;
				clear: both;
				font-weight: 500;
				color: #333;
				white-space: nowrap;
				font-size: 15px;
				
				img {
					border-radius: 100%;
					-webkit-border-radius: 100%;
					margin-right: 5px;
				}
				&:hover {
					background-color:#f2f2f4;
				}
			}
			&.selected{
				a{
					background-color: var(--primary);
					color: $white;
				}
			}
		}
	}
	&:after{
		content: "\f107";
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		font-weight: 700;
		line-height: 1;
		font-size: 14px;
		font-family: 'Font Awesome 6 Free';
		color: var(--primary);
		z-index: 9;
	}
}

.form-select.btn-group{
	padding: 10px 20px;
	border-width: 1px;
	&.show{
		box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1)!important;
		border-color: var(--primary);
	}
	.form-control{
		.dropdown-toggle{
			&.btn-default{
				padding: 0;
			}
		}
	}
	.dropdown-toggle{
		.caret{
			font-size: 10px;
			top: 50%;
			right: 5px;
			transform: translateY(-50%);
			&:before{
				content: "\f078";
			}
		}
	}
}
.custom-file-label{
	box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
	height: 45px;
	border-radius: 30px!important;
	padding: 10px 20px;
	font-size: 15px;
	border-color: transparent;
	&:after{
		content: none!important;
	}
}

.dz-form{
	&.radius-no{
		.form-control{
			border-radius:0;
		}
	}
	&.style-1{
		textarea{
			height: 185px;
			padding: 15px 20px;
		}
	}
}
.form-check {
    margin-bottom: 10px;
    margin-left: 10px;
}
.form-check-input[type="checkbox"]{
    height: 25px;
    width: 25px;
    border: 2px solid var(--primary);
	margin-left: -35px;
	margin-top: 1px;
	
	&:checked{
		background-color: var(--primary);
	}
}
.form-group{
    margin-bottom: 40px;
}

.default-select{
	&.style-1{
		.btn.dropdown-toggle{
			padding: 14px 20px;
			background-color: #fff;
			border: 1px solid $border!important;
			border-radius: var(--border-radius-base);
			color: #777!important;
			
			&:active,
			&:focus,
			&:hover{
				border-color: var(--primary)!important;				
			}
		}
	}
	&.style-2{
		width: 100%!important;
		.btn.dropdown-toggle{
			@extend .form-control;
			background-color: var(--rgba-primary-1);
			border: 1px solid #EEEEEE!important;
			padding: 0 20px;
			line-height: 53px;
			&.show,
			&:focus,
			&:active{
				color: $body-color!important;
				border: 1px solid var(--primary)!important;
				background-color: #fff;
			}
			&:hover{
				color: $body-color!important;
			}
		}
	}
	&.style-3{
		.btn.dropdown-toggle{
			@extend .form-control;
			border: 1px solid $border!important;
			padding: 0 20px;
			line-height: 53px;
			&.show,
			&:focus,
			&:active{
				color: $body-color!important;
				border: 1px solid var(--primary)!important;
				background-color: #fff;
			}
			&:hover{
				color: $body-color!important;
			}
		}
	}	
}


.checkout-form{
	margin-bottom: 40px;
	.form-label{
		font-size: 14px;
		font-weight: 600;
		color: var(--primary);
	}
	.form-control{
		background: none;
		border: 1px solid transparent;
		border-bottom: 2px solid #E1E1E1;
		border-radius: 0;
		padding: 10px 0;
		&::placeholder {
			font-weight: 600;
			font-size: 16px;
			color: var(--seconday);
		}
	}
}

.tag-donate{
	&.style-1{	
		display: flex;
		flex-wrap: wrap;
		.donate-categories{
			position:relative;
			text-align:center;
			.form-check{
				border: 1px solid $border;
				border-radius:var(--border-radius-base);
				padding-left: 0;
				display: inline-block;
				margin-left: 0;
				margin-right: 10px;
				
				.form-check-input{
					display: none;
					&:checked + .form-check-label {
						background-color: var(--primary);
						color: #fff;
						border-radius: var(--border-radius-base);										
					}
				}
				.form-check-label{
					padding: 5px 10px;
					font-size: 13px;
					cursor: pointer;
				}
			}
		}
	}
	&.style-2{	
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
		.donate-categories{
			position:relative;
			text-align:center;
			.form-check{
				border: 1px solid #F0F0F0;
				border-radius:var(--border-radius-base);
				padding-left: 0;
				display: inline-block;
			
				.form-check-input{
					display: none;
					&:checked + .form-check-label {
						background-color: var(--primary);
						color: #fff;
						border-radius: var(--border-radius-base);										
					}
				}
				.form-check-label{
					padding: 6px 12px;
				}
			}
		}
	}
	&.style-3{
		justify-content: center;
		margin-bottom: 50px;
		display: flex;
		flex-wrap: wrap;
		
		@include respond('phone'){
			margin-bottom: 20px;
		}
		.donate-categories{
			position:relative;
			text-align:center;
			.form-check{
				margin-left: 0;
				margin-right: 10px;
				padding-left: 0;
				.form-check-label {
					border: 2px solid var(--title);
					border-radius: var(--border-radius-base);
					padding: 7px 20px;
					font-size: 18px;
					cursor: pointer;
					color: var(--title);
					font-weight: 500;
					
					@include respond('phone'){
						padding: 5px 12px;
						font-size: 15px;
					}
				}
				.form-check-input{
					display: none;
					&:checked + .form-check-label {
						background-color: var(--primary);
						border: 2px solid var(--primary);
						color: #fff;
						border-radius: var(--border-radius-base);
					}
				}
			}
		}
	}
}

.input-group-text{
	&.checkout-text{
		background: none;
		border-color: transparent;
		position: relative;
		right: 60px;
		i{
			font-size:20px;
			font-weight:600;
		}
	}
}
.form-control {
	&.default-select{
		.btn{
			background: transparent;
			border-width: 0 0 2px;
			border-radius: 0;
			padding: 15px 10px!important;
			border-color: #E1E1E1;
			padding: 10px 20px;
			&:focus{
				outline: 0!important;
			}
			&:after{
				content:"\f107";
				position:absolute;
				font-family:"Font Awesome 5 Free";
				border-style:none;
				right:0;
			}
		}
		.filter-option-inner-inner {
			font-weight: 600;
			font-size: 16px;
		}
	}
}

.form-wrapper{
	background-color: #fff;
	border-radius: 14px;
	box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
	z-index: 2;
	position: relative;
	padding: 50px;
	
	@include respond('phone-land'){
		padding: 30px;
	}
	
	.input-group{
		margin-bottom: 30px;
		@include respond('phone-land'){
			margin-bottom: 20px;
		}
	}
	.title{
		margin-bottom: 30px;
		@include respond('phone-land'){
			margin-bottom: 20px;
		}
	}
	h2.title{
		@include respond('phone-land'){
			font-size: 24px;
		}
	}
	
	// Style 3
	&.style-3{
		margin-top: -60px;
		overflow: hidden;
		
		.form-group{
			.text-label{
				color: var(--title);
				font-weight: 700;
			}
		}
		@include respond('phone-land'){
			margin-top: -50px;
		}	
	}
}

.form-wizard{
	border: none;
	
	&.sw>.tab-content{
		position: relative;
		overflow: unset!important; 
		height: auto!important;
		margin-bottom: 30px;
		@include respond('phone-land'){
			margin-bottom: 20px;
		}
	}
	&.sw>.tab-content>.tab-pane{
		padding: 0;
	}
	
	.target-bx{
		background: $light;
		padding: 25px 30px;
		margin-bottom: 30px;
		@include respond('phone-land'){
			padding: 20px 25px;
		}
		ol{
			padding-left: 22px;
			margin-bottom: 0;
			li{
				list-style: disc;
				@include respond('phone-land'){
					font-size: 15px;
				}
			}
		}
	}
	.toolbar-bottom{
		padding: 0;
	}
	.nav-wizard{
		box-shadow: unset !important;
		margin: -50px -50px 50px;
		
		@include respond('phone-land'){
			flex-direction: row!important;
			margin: -30px -30px 30px;
		}
		
		.nav-link {
			padding: 15px 30px;
			color: var(--primary)!important;
			font-weight: 500;
			font-size: 18px;
			font-family: var(--font-family-title);
			background-color: $light;
			margin-right: 0;
			line-height: 1.6;
			@include respond('phone-land'){
				padding: 10px 20px;
			}
			
			&:after{
				content: none;
			}
			&.active,
			&.done{
				background-color: var(--secondary);
				color: #fff!important;
				border-right: 1px solid rgba(255, 255, 255, 0.2)!important;
			}
		}
		& > li{
			.nav-link{
				border-right: 1px solid rgba(0,0,0,0.1)!important;
				border-bottom: 1px solid rgba(0,0,0,0.1);
			}
			&:last-child{
				.nav-link{
					border-right: 0!important;				
				}
			}
		}
	}
	.btn.sw-btn{
		border: 1px solid var(--primary);
		background-color: var(--primary);
		padding: 10px 20px;
		@include respond('phone-land'){
			padding: 8px 14px;
		}
	}
}

.form-wrapper1{
	padding: 60px 0 30px;
	
	@include respond('tab-port'){
		padding: 40px 0 40px;		
	}
	@include respond('phone'){
		.g-4,
		.gx-4{
			--bs-gutter-y: 1rem;
			--bs-gutter-x: 1rem;
		}
		.btn{	
			padding: 16px 20px;
		}
	}
	.section-head{
		margin-bottom: 30px;
		
		h2{
			font-size: 40px;
		}
		@include respond('phone'){
			h2{
				font-size: 32px;
			}
		}
	}
}


// donate-form
.donate-form{
	padding: 85px 50px;
	text-align: center;
	border-radius: var(--border-radius-base);
	position: relative;
	background-repeat: no-repeat;
	background-position: top;
	background-color: var(--secondary);
	background-size: 100%;
	background-blend-mode: luminosity;
	z-index: 1;
	
	@include respond('tab-land'){
		padding: 65px 30px;
	}
	@include respond('tab-port'){
		margin: 0;
	}
	@include respond('phone'){
		padding: 35px 25px;	
	}
	.title{
		margin-bottom: 20px;
		font-size: 40px;
		font-weight: 700;
		
		@include respond('phone'){
			font-size: 32px;
			margin-bottom: 15px;
		}
	}

	&:after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: var(--border-radius-base);
		// background: -moz-linear-gradient(top,  rgba(247,207,71,0.5) 0%, rgba(247,207,71,0.52) 1%, rgba(247,207,71,1) 24%, rgba(247,207,71,1) 100%);
		// background: -webkit-linear-gradient(top,  rgba(247,207,71,0.5) 0%,rgba(247,207,71,0.52) 1%,rgba(247,207,71,1) 24%,rgba(247,207,71,1) 100%);
		background: linear-gradient(to bottom,  rgba(247,207,71,0.5) 0%,rgba(247,207,71,0.52) 1%,rgba(247,207,71,1) 24%,rgba(247,207,71,1) 100%);
	}
	.donate-info{
		margin-bottom: 50px;
		@include respond('phone'){
			margin-bottom: 20px;
		}
		p{
			color: var(--title);	
			@include respond('phone'){
				font-size: 14px;
			}
		}
	}
	.form-group {
		@include respond('phone'){
			margin-bottom: 30px;
		}
	}
	.form-control{
		margin-top: 20px;
		border-color: var(--title) !important;
		border-width: 0px 0 3px 0;
		border-style: solid;
		color: #777;
		background: transparent;
		border-radius: 0!important;
		text-align: center;
		font-size: 20px;
		padding-bottom: 40px;
		
		&::placeholder{
			color: #003B4A;
			opacity: 0.4;
			font-size: 24px;
		}
		
		@include respond('phone'){
			font-size: 16px;
			margin-top: 0px;
			border-width: 0px 0 2px 0;
			padding: 10px;
			
			&::placeholder{
				font-size: 16px;
			}
		}
	}
}


// Google Recaptcha
.input-recaptcha{
	margin-bottom: 30px;
	
	@include respond('phone'){
		transform:scale(0.77);
		// -moz-transform:scale(0.77);
		// -o-transform:scale(0.77);
		// -webkit-transform:scale(0.77);
		transform-origin:0 0;
		// -moz-transform-origin:0 0;
		// -o-transform-origin:0 0;
		// -webkit-transform-origin:0 0;
		margin-bottom: 0px;
	}
}